@font-face {
  font-family: 'MinionPro-Regular';
  font-display: swap;
  src: url('./fonts/MinionPro-Regular.eot');
  src: url('./fonts/MinionPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/MinionPro-Regular.woff2') format('woff2'),
    url('./fonts/MinionPro-Regular.woff') format('woff'),
    url('./fonts/MinionPro-Regular.ttf') format('truetype'),
    url('./fonts/MinionPro-Regular.svg#minion_proregular') format('svg');
}

@font-face {
  font-family: 'NeutraText-Bold';
  font-display: swap;
  src: url('./fonts/NeutraText-Bold.eot');
  src: url('./fonts/NeutraText-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeutraText-Bold.woff2') format('woff2'),
    url('./fonts/NeutraText-Bold.woff') format('woff'),
    url('./fonts/NeutraText-Bold.ttf') format('truetype'),
    url('./fonts/NeutraText-Bold.svg#neutraface_textbold') format('svg');
}

@font-face {
  font-family: 'NeutraText-Book';
  font-display: swap;
  src: url('./fonts/NeutraText-Book.eot');
  src: url('./fonts/NeutraText-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeutraText-Book.woff2') format('woff2'),
    url('./fonts/NeutraText-Book.woff') format('woff'),
    url('./fonts/NeutraText-Book.ttf') format('truetype'),
    url('./fonts/NeutraText-Book.svg#neutraface_textbook') format('svg');
}
